<template>
	<div class="self-service-wizard-step">
		<div
			class="headline"
			v-if="$slots['headline']"
		>
			<slot name="headline"></slot>
		</div>
		<div class="main">
			<slot name="main"></slot>
		</div>
		<div class="actions">
			<slot name="actions"></slot>
		</div>
		<slot name="tracking"></slot>
	</div>
</template>

<script>
export default defineNuxtComponent({
	name: "SelfServiceWizardStep",
});
</script>

<style lang="scss">
.self-service-wizard-step {
	max-width: 600px;
	margin: 0 auto;

	@include font-text-m;

	strong {
		font-weight: bold;
	}
}
</style>

<style lang="scss" scoped>
.headline {
	&:deep() {
		h2 {
			@include font-title-2xl;
		}

		h3 {
			@include font-title-xl;
		}

		p,
		div {
			@include breakpoint(mobile) {
				text-align: left;
			}
		}

		h1,
		h2,
		h3 {
			margin-bottom: $spacing-m;

			+ * {
				margin-bottom: $spacing-m;
			}
		}

		+ .main {
			margin-top: $spacing-2xl;
		}
	}
}

.actions {
	text-align: center;
}

.modal-wrapper {
	.headline {
		h2 {
			@include font-title-xl;
		}
	}
}
</style>
